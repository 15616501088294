import type { AlgoliaProduct } from '@backmarket/http-api/src/api-specs-search-reco/search/searchAlgolia'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { canUseLocalStorage } from '@backmarket/utils/composables/useLocalStorage'
import { isBrowser } from '@backmarket/utils/env/isBrowser'

import type { ComparisonProduct, Product } from '../models/product'

declare global {
  interface Window {
    ebRecoIds?: string[]
  }
}

export const getUserRecommendationId = () => {
  if (!canUseLocalStorage()) {
    return null
  }

  return localStorage?.getItem('attraqtsessionid')
}

export const setRecommendationDebugExtension = (id: string | null = null) => {
  if (!isBrowser()) {
    return
  }
  if (!id) {
    return
  }
  window.ebRecoIds = [...new Set(window.ebRecoIds).add(id)]
}

export const saveProductInfos = ({
  id,
  price,
  source,
  reviewRating,
}: {
  id: string
  price: string
  source: string
  reviewRating?: string
}) => {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(
      id,
      JSON.stringify({ price, source, reviewRating }),
    )
  }
}

export const getProductInfos = (id: string) => {
  const logger = useLogger()
  try {
    if (window.sessionStorage) {
      const item = window.sessionStorage.getItem(id)

      return item ? JSON.parse(item) : {}
    }
  } catch (error) {
    logger.info('[S&R][Error][ProductPage] Error accessing sessionStorage', {
      error: error instanceof Error ? error : undefined,
      owners: ['bot-squad-search-and-recommendation-front'],
    })
  }

  return {}
}

export const getPercent = (
  price: string | number,
  reference: string | number,
) => {
  const number = typeof price === 'string' ? parseInt(price, 10) : price
  const referencedNumber =
    typeof reference === 'string' ? parseInt(reference, 10) : reference

  return number < referencedNumber
    ? Math.ceil(((number - referencedNumber) / referencedNumber) * 100)
    : null
}

export function logDesync({
  id,
  isOutOfStock,
  price,
}: {
  id: string
  isOutOfStock?: boolean
  price?: string
}) {
  const {
    price: storedPrice,
    source,
  }: {
    price?: string
    reviewRating?: string
    source?: string
  } = getProductInfos(id)

  const logger = useLogger()

  if (source === undefined || storedPrice === undefined) {
    return
  }

  const numericPrice = price ? parseFloat(price) : NaN
  const numericStoredPrice = parseFloat(storedPrice)
  if (!Number.isNaN(numericPrice) && numericPrice !== numericStoredPrice) {
    logger.info('[S&R][Error][ProductPage] Price desync detected', {
      id,
      cardPrice: storedPrice,
      productPrice: price,
      source,
      owners: ['bot-squad-search-and-recommendation-front'],
    })
  }

  if (isOutOfStock) {
    logger.info('[S&R][Error][ProductPage] Stock desync detected', {
      id,
      source,
      owners: ['bot-squad-search-and-recommendation-front'],
    })
  }
}

export function algoliaHitToVariant(hit: AlgoliaProduct): Product {
  return {
    availableStock: hit.stockRaw,
    brand: hit.brand,
    brandClean: hit.brand_clean,
    categoryId: hit.cat_id,
    category: hit.category_3,
    description: hit.sub_title_elements?.join(' - ') ?? '',
    grade: {
      name: hit.backbox_grade_label,
      value: hit.backbox_grade_value,
    },
    id: hit.id,
    objectId: hit.objectID,
    image: hit.image1,
    listingId: `${hit.listingID}`,
    model: hit.model,
    modelClean: hit.model_clean,
    price: { amount: `${hit.price}`, currency: hit.currency },
    priceNew: hit.referencePrice,
    productPageLink: hit.link_grade_v2,
    reviewRating: hit.reviewRating,
    sellerId: `${hit.merchant_id}`,
    title: hit.title_model,
    warrantyDuration: hit.warranty,
  }
}

export function algoliaHitToProduct(hit: AlgoliaProduct): Product {
  const specEntries = Object.entries(hit.variant_fields).map(
    ([key, specification]) => {
      const cleanSpec = specification
        .map((val) => {
          return typeof val === 'string' ? { name: val } : val
        })
        .filter((item) => item.name)

      return [key, cleanSpec]
    },
  )
  const specifications = Object.fromEntries(specEntries)

  return {
    id: hit.objectID,
    objectId: hit.objectID,
    title: hit.title_model,
    listingId: `${hit.listingID}`,
    model: hit.model,
    modelClean: hit.model_clean,
    brand: hit.brand,
    brandClean: hit.brand_clean,
    sellerId: `${hit.merchant_id}`,
    categoryId: hit.cat_id,
    category: hit.category_3,
    grade: {
      name: hit.backbox_grade_label,
      value: hit.backbox_grade_value,
    },
    productPageLink: hit.link_grade_v2,
    priceNew: hit.referencePrice,
    price: {
      amount: `${hit.price}`,
      currency: hit.currency,
    },
    reviewRating: hit.reviewRating,
    availableStock: hit.stockRaw,
    warrantyDuration: hit.warranty,
    image: hit.image1,
    specifications,
    scores: {
      lifeExpectancy: hit.life_expectancy_score,
      performances: hit.performances_score,
      camera: hit.camera_score,
      screenQuality: hit.screen_quality_score,
      soundQuality: hit.sound_quality_score,
      multimedia: hit.multimedia_score,
    },
  }
}

export function productToComparison(product: Product): ComparisonProduct {
  return {
    title: product.title,
    image: product.image,
    brand: product.brand,
    model: product.model,
    id: product.id,
    category: product.category,
    categoryId: product.categoryId,
    url: product.productPageLink,
    price: product.price,
    priceNew: product.priceNew,
    discountedPrice: product.discountedPrice,
    reviewRating: product.reviewRating,
    listingId: product.listingId,
    specifications: product.specifications,
    scores: product.scores,
  }
}
